import { EditButton, DeleteButton, Stack } from "@pankod/refine-mui";
import Image from "components/Image";
import { API_URL } from "constants";

export default {
  title: "rooms",
  resource: "rooms",
  canCreate: true,
  columns: [
    {
      field: "image",
      headerName: "Image",
      flex: 1,
      minWidth: 100,
      maxWidth: 100,
      renderCell: function render(params) {
        return (
          <Stack
            direction="column"
            justifyContent="center"
            sx={{ width: "100%", height: "100%" }}
          >
            <Image
              src={
                params.row?.gallery && params.row?.gallery.length > 0
                  ? API_URL + params.row?.gallery[0]?.url
                  : "/oops.png"
              }
              alt={params.row.image?.name}
              sx={{
                borderRadius: 5,
              }}
              height="100%"
              width="100%"
            />
          </Stack>
        );
      },
    },
    { field: "title", headerName: "Title", flex: 1, minWidth: 300 },
    {
      headerName: "Actions",
      field: "actions",
      minWidth: 50,
      renderCell: function render(params) {
        return (
          <Stack direction="row" spacing={1}>
            <EditButton hideText recordItemId={params.row.id} />
            <DeleteButton hideText recordItemId={params.row.id} />
          </Stack>
        );
      },
    },
  ],
};
