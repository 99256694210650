import { EditButton, DeleteButton, Stack } from "@pankod/refine-mui";

export default {
  title: "Videos",
  resource: "videos",
  canCreate: true,
  columns: [
    { field: "title", headerName: "Title", flex: 1, minWidth: 300 },
    { field: "url", headerName: "Video ID", flex: 1, minWidth: 300 },
    {
      headerName: "Actions",
      field: "actions",
      minWidth: 50,
      renderCell: function render(params) {
        return (
          <Stack direction="row" spacing={1}>
            <EditButton hideText recordItemId={params.row.id} />
            <DeleteButton hideText recordItemId={params.row.id} />
          </Stack>
        );
      },
    },
  ],
};
