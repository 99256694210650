// i18n
import "locales/i18n";

// highlight
import "utils/highlight";

// scroll bar
import "simplebar/src/simplebar.css";

// editor
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

// lazy image
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-lazy-load-image-component/src/effects/opacity.css";
import "react-lazy-load-image-component/src/effects/black-and-white.css";

import { API_URL } from "./constants";

// refine
import {
  ErrorComponent,
  ReadyPage,
  CssBaseline,
  createTheme,
  ThemeProvider,
  GlobalStyles,
  RefineSnackbarProvider,
  notificationProvider,
} from "@pankod/refine-mui";

// refine utils
import { Refine, Authenticated } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import { DataProvider } from "strapiDataProvider";
import { authProvider } from "authProvider";
import { accessControlProvider } from "accessControlProvider";

// utils
import { ColorModeContextProvider } from "contexts";
import { AuthProvider } from "contexts/AuthContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "utils/axios";

// custom pages and components
import CustomLayout from "components/CustomLayout";
import CustomCreateEdit from "components/CustomCreateEdit";
import CustomList from "components/CustomList";

import LoginPage from "components/user/LoginPage";
import RegisterPage from "components/user/RegisterPage";
import PasswordForgot from "components/user/PasswordForgot";
import PasswordReset from "components/user/PasswordReset";
import Dashboard from "pages/Dashboard";
import Settings from "pages/Settings";
import Unauthorized from "pages/Unauthorized";
import NotFound from "pages/NotFound";
import AccountDeleted from "pages/AccountDeleted";
import Privacy from "pages/Privacy";

// resources
import RoomsListConfig from "pages/rooms/ListConfig";
import RoomFields from "pages/rooms/Fields";

import LinksListConfig from "pages/links/ListConfig";
import LinksFields from "pages/links/Fields";

import PostsListConfig from "pages/posts/ListConfig";
import PostFields from "pages/posts/Fields";

import VideosList from "pages/videos/ListConfig";
import VideosFields from "pages/videos/Fields";

// Icons

export const themeOptions = {
  palette: {
    primary: {
      main: "#8d99ae",
    },
    secondary: {
      main: "#2b2d42",
    },
    info: {
      main: "#edf2f4",
    },
  },
  shape: { borderRadius: 8 },
};

const theme = createTheme(themeOptions);
const queryClient = new QueryClient();
window.queryClient = queryClient;

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <ColorModeContextProvider>
        <CssBaseline />
        <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
        <RefineSnackbarProvider>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              <Refine
                routerProvider={{
                  ...routerProvider,
                  routes: [
                    {
                      element: <RegisterPage />,
                      path: "/register",
                      layout: false,
                    },
                    {
                      element: <PasswordForgot />,
                      path: "/forgot-password",
                      layout: false,
                    },
                    {
                      element: <PasswordReset />,
                      path: "/reset-password",
                      layout: false,
                    },
                    {
                      element: <NotFound />,
                      path: "/not-found",
                      layout: false,
                    },
                    {
                      element: (
                        <Authenticated>
                          <Settings />
                        </Authenticated>
                      ),
                      path: "/settings",
                      layout: true,
                    },
                    // example of authorization with permissions
                    // {
                    //   element: (
                    //     <CanAccess action="customer" fallback={<Unauthorized />}>
                    //       <ProfileView />
                    //     </CanAccess>
                    //   ),
                    //   path: "/profile",
                    //   layout: false,
                    // },
                    {
                      element: <Privacy />,
                      path: "/privacy",
                      layout: false,
                    },
                    {
                      element: <AccountDeleted />,
                      path: "/account-deleted",
                      layout: false,
                    },
                  ],
                }}
                resources={[
                  // Custom Resources ----------------------
                  // {
                  //   name: "",
                  //   icon: <Icon />,
                  //   options: { label: "Label" },
                  //   list: List,
                  //   create: Create,
                  //   edit: Edit,
                  // },
                  // Standard Resources --------------------
                  // {
                  //   name: "recource-endpoint",
                  //   options: { label: "Resource Name" },
                  //   list: ResourceList,
                  //   create: () =>
                  //     CustomCreateEdit({
                  //       isCreate: true,
                  //       Fields: ResourceFields,
                  //     }),
                  //   edit: () =>
                  //     CustomCreateEdit({
                  //       isCreate: false,
                  //       Fields: ResourceFields,
                  //     }),
                  // },
                  {
                    name: "rooms",
                    options: { label: "Rooms" },
                    list: () => CustomList({ ...RoomsListConfig }),
                    create: () =>
                      CustomCreateEdit({
                        routeName: "rooms",
                        isCreate: true,
                        Fields: RoomFields,
                      }),
                    edit: () =>
                      CustomCreateEdit({
                        routeName: "rooms",
                        isCreate: false,
                        Fields: RoomFields,
                      }),
                  },
                  {
                    name: "links",
                    options: { label: "Links" },
                    list: () => CustomList({ ...LinksListConfig }),
                    create: () =>
                      CustomCreateEdit({
                        routeName: "links",
                        isCreate: true,
                        Fields: LinksFields,
                      }),
                    edit: () =>
                      CustomCreateEdit({
                        routeName: "links",
                        isCreate: false,
                        Fields: LinksFields,
                      }),
                  },
                  {
                    name: "videos",
                    options: { label: "Videos" },
                    list: () => CustomList({ ...VideosList }),
                    create: () =>
                      CustomCreateEdit({
                        noLocalize: true,
                        routeName: "videos",
                        isCreate: true,
                        Fields: VideosFields,
                      }),
                    edit: () =>
                      CustomCreateEdit({
                        noLocalize: true,
                        routeName: "videos",
                        isCreate: false,
                        Fields: VideosFields,
                      }),
                  },
                  {
                    name: "posts",
                    options: { label: "Posts" },
                    list: () => CustomList({ ...PostsListConfig }),
                    create: () =>
                      CustomCreateEdit({
                        routeName: "posts",
                        isCreate: true,
                        Fields: PostFields,
                      }),
                    edit: () =>
                      CustomCreateEdit({
                        routeName: "posts",
                        isCreate: false,
                        Fields: PostFields,
                      }),
                  },
                ]}
                authProvider={authProvider}
                accessControlProvider={accessControlProvider}
                dataProvider={DataProvider(API_URL + "/api", axios)}
                notificationProvider={notificationProvider}
                Title={({ collapsed }) => (
                  <div
                    style={{
                      height: 200,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {collapsed && (
                      <img
                        src="/logo.png"
                        alt="Logo"
                        style={{ width: "50px" }}
                      />
                    )}
                    {!collapsed && (
                      <img
                        src="/logo.png"
                        alt="Logo"
                        style={{ width: "100px" }}
                      />
                    )}
                  </div>
                )}
                Layout={CustomLayout}
                DashboardPage={Dashboard}
                ReadyPage={ReadyPage}
                LoginPage={LoginPage}
                catchAll={<ErrorComponent />}
              />
            </AuthProvider>
          </QueryClientProvider>
        </RefineSnackbarProvider>
        <ToastContainer />
      </ColorModeContextProvider>
    </ThemeProvider>
  );
};

export default App;
