// @mui
import { enUS, itIT, ptPT, ruRU } from "@mui/material/locale";

// MULTI LANGUAGES
// Please remove `localStorage` when you change settings.

export const allLangs = [
  {
    label: "Italiano",
    value: "it",
    systemValue: itIT,
    icon: "/flags/ic_flag_it.svg",
  },
  {
    label: "English",
    value: "en-GB",
    systemValue: enUS,
    icon: "/flags/ic_flag_en.svg",
  },
  {
    label: "Português",
    value: "pt-PT",
    systemValue: ptPT,
    icon: "/flags/ic_flag_pt.svg",
  },
  {
    label: "Русский",
    value: "ru-RU",
    systemValue: ruRU,
    icon: "/flags/ic_flag_ru.png",
  },
];

export const defaultLang = allLangs[0]; // Italian
